import MainPage from './page/home/MainPage';
import React from 'react';

function App() {
  return (
    <MainPage/>
  );
}

export default App;
